import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  close(event) {
    event.preventDefault()
    this.element.classList.remove("opacity-100")
    this.element.classList.add("opacity-0")
    setTimeout(() => this.element.remove(), 250)
  }
}
