// src/controllers/hello_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets() {
    return []
  }

  initialize() {
    this.isShowing = false
    this.showListenerId = "mgrokd:sidebar-mobile:show"
  }

  connect() {
    this.showListener = document.addEventListener(
      this.showListenerId,
      this.handleShowEvent.bind(this)
    )
  }

  disconnect() {
    document.removeEventListener(this.showListenerId, this.showListener)
  }

  hide(event) {
    // event.preventDefault()
    if (this.isShowing) {
      this.isShowing = false
      this.element.classList.add("hidden")
    }
  }

  handleShowEvent(event) {
    // event.preventDefault()
    if (!this.isShowing) {
      this.isShowing = true
      this.element.classList.remove("hidden")
    }
  }
}
