// src/controllers/hello_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  initialize() {
    this.count = 0
    this.minLines = 4
  }

  connect() {
    if (this.element.hasAttribute("rows")) this.minLines = this.element.getAttribute("rows")
    this.setRows()
  }

  getCount() {
    let count = (this.element.value.match(/\n/g) || []).length  + 1
    if (count < this.minLines) return this.minLines
    return count
  }

  setRows() {
    let count = this.getCount()
    if (this.count === count) return
    this.count = count
    this.element.rows = this.count
  }

  onInput(event) {
    this.setRows()
  }
}
