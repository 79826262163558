import * as Turbo from "@hotwired/turbo"

import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

window.Stimulus = Application.start()
const context = require.context("./controllers", true, /\.js$/)
Stimulus.load(definitionsFromContext(context))

document.addEventListener("turbo:frame-missing", event => {
  // When the response is a redirect, prevent loading the frame content
  // and perform the redirect
  if (event.detail.response.redirected) {
    event.preventDefault()
    event.detail.visit(event.detail.response)
  }
})
