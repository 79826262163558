import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets() {
    return ["source", "notification"]
  }

  initialize() {
    this.clickable = true
  }

  hideAnimation() {
    this.notificationTarget.classList.remove("opacity-100")
    this.notificationTarget.classList.remove("duration-100")
    this.notificationTarget.classList.add("opacity-0")
    this.notificationTarget.classList.add("duration-1000")
    setTimeout(() => this.hideNotification(), 1000)

  }

  hideNotification() {
    this.notificationTarget.classList.add("hidden")
    this.clickable = true
  }

  notify() {
    this.notificationTarget.classList.remove("hidden")
    this.notificationTarget.classList.remove("opacity-0")
    this.notificationTarget.classList.remove("duration-1000")
    this.notificationTarget.classList.add("opacity-100")
    this.notificationTarget.classList.add("duration-100")
    setTimeout(() => this.hideAnimation(), 100)
  }

  copy(event) {
    event.preventDefault()
    if (!this.clickable) return
    this.clickable = false
    navigator.clipboard.writeText(this.sourceTarget.value).then().catch()
    if (this.hasNotificationTarget) this.notify()
  }
}
